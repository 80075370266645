import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue';
import ForgotPassword from '../views/account/ForgotPassword.vue';
import store from '../store'

// import utils from '../common/Utils'
// import TokenService from "../services/token.service";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { requiresAuth: true },
    },
    {
        path: '/about',
        name: 'About',
        title: 'TBP - About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: "/login",
        component: Login,
        title: 'TBP - About',
    },
    {
        path: "/mapview",
        component: () => import(/* webpackChunkName: "about" */ '../views/MapView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: "/pickups",
        component: () => import(/* webpackChunkName: "about" */ '../views/Pickups.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: "/account",
        name: 'Account',
        component: () => import(/* webpackChunkName: "about" */ '../views/Account.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/account/verify-email',
        name: 'Verify-Email',
        component: () => import(/* webpackChunkName: "about" */ '../views/account/VerifyEmail.vue'),
        // meta: { requiresAuth: false },
    },
    {
        path: '/account/forgot-password',
        name: 'Forgot-Password',
        component: ForgotPassword, //() => import(/* webpackChunkName: "forgot-password" */ '../views/account/ForgotPassword.vue'),
        // meta: { requiresAuth: false },
    },
    {
        path: '/account/reset-password',
        name: 'reset-password',
        component: () => import(/* webpackChunkName: "about" */ '../views/account/ResetPassword.vue'),
        // meta: { requiresAuth: false },
    },    
    {
        path: "/program/:pid",
        component: () => import(/* webpackChunkName: "about" */ '../views/Program.vue'),
        meta: { requiresAuth: true },
    },
    //{
    //    path: '/api'
    //},
    { 
        path: '/:pathMatch(.*)*', 
        component: () => import(/* webpackChunkName: "about" */ '../views/PathNotFound.vue'),
        // component: PathNotFound 
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
//   // console.log(to, from)
//   // let token = TokenService.getLocalAccessToken()
//   // console.log(token)
//   // if (token) {
//   //   const jwtPayload = utils.parseJwt(token);

//   //   if (jwtPayload.exp < Date.now()/1000) {
//   //       // token expired
//   //       console.log('token expired')
//   //       store.dispatch("auth/logout");
//   //       next("/login");
//   //       return
//   //   }
//   //   next();
//   // } 
//   // else {
//   //   store.dispatch("auth/logout");
//   //   next("/login");
//   // }


  if(to.matched.some(record => record.meta.requiresAuth)) {
    console.log('Router check loggedIn', store.state.auth.status.loggedIn)
    if (store.state.auth.status.loggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
