<template>
  <div class="home">
    <center>
      <h1 class="heading">Välkommen till Byggprofiler's transportportal</h1>
    </center>
    <img src="../assets/1800x1000_02_ny.jpg" style="width: 100%;">
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
}
</script>
<style scoped>

h1.heading {
  font-size: 28px;
  padding: 2%;
}

.home {
  color: rgb(75, 75, 74);
  text-transform: uppercase;

  height: 100%;
  /* background-image: url("../assets/1800x1000_02_ny.jpg");
  background-repeat: no-repeat;
  background-size: 100%; */
  /* text-align: center; */
}

</style>
