import api from './api';

class DataService {
    dataQuery(object, params) {
        return api
            .post("/GenericData/ObjectDataQuery", {
                object,
                params
            })
            .then((response) => {
                console.info('dataQuery', response)
                return response.data;
            })
            .catch((error) => {
                console.log('dataQuery', error)
                if (error.response)
                    throw error.response.data.error
                else 
                    throw error
                //return error;
            });
    }

}

export default new DataService();
