import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { fas } from '@fortawesome/free-solid-svg-icons';
//import { far } from '@fortawesome/free-regular-svg-icons';

// import {
//   faHome,
//   faUser,
//   faUserPlus,
//   faSignInAlt,
//   faSignOutAlt,
// } from "@fortawesome/free-solid-svg-icons";

// library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt);

library.add(fas);

export { FontAwesomeIcon };
