import axios from "axios";

const instance = axios.create({
    // baseURL: "http://localhost:4000/api",
    // baseURL: "http://192.168.86.28:8086/api",
    // D�p inte Env variabel med VUE_APP prefix d� blir den embedded i bundle.
    baseURL: (process.env.VUE_APP_API_BASE_URL !== undefined) ? process.env.VUE_APP_API_BASE_URL : '/api',
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true
});

export default instance;
