<template>
  <Base></Base>
  <span id="tokenTtl">Time to token expires: {{tokenTtl}}</span>
  <!-- <Base v-if="$store.state.auth.status.loggedIn"></Base>
  <Login v-if="!$store.state.auth.status.loggedIn"></Login> -->
</template>
<script>
  import { ref, onMounted, onUnmounted } from 'vue'
  import axiosInstance from "./services/api";
  import EventBus from "./common/EventBus";
  import TokenService from "./services/token.service";
  import utils from './common/Utils'
  import store from './store'
  import Base from "@/views/Base.vue";
  import { useRouter } from 'vue-router';

  // import Login from "@/views/Login.vue";

  
  export default {
    components: {
    Base,
  },
    setup() {
      const router = useRouter();
      let tokenTtl = ref(0);
      let refreshingToken = false;

      function logOut() {
          store.dispatch("auth/logout");
          router.push("/login");
      }

      /**
       * Keep an eye on the JWT and refresh the accesstoken when it expires.
       * If accesstoken fails to refresh logout the user.
       * This is only active when user is logged in.
       */
      console.log('Setting up token checker')
      const checkJwtInterval = setInterval(async () => {
        if (store.state.auth.status.loggedIn) {
          let token = TokenService.getLocalAccessToken();
          
          if (token) {
            if (refreshingToken)
              return;

            const jwtPayload = utils.parseJwt(token);
          
            if (jwtPayload.exp < Date.now() / 1000) {
              refreshingToken = true;
              try {
                console.log('Try to refresh token')
                const rs = await axiosInstance.post("/accounts/refresh-token", {
                  refreshToken: TokenService.getLocalRefreshToken(),
                });

                const { accessToken, refreshToken } = rs.data;

                store.dispatch('auth/refreshToken', accessToken);
                TokenService.updateLocalAccessToken(accessToken);
                TokenService.updateLocalRefreshToken(refreshToken);
              } 
              catch (e) {
                console.error('Failed to refresh token', e)
                logOut()
              }
              refreshingToken = false;
            } else 
            {
              tokenTtl.value = parseInt(parseInt(new Date(jwtPayload.exp * 1000) - Date.now()) / (1000))
            }
            
          }
          else {
            console.log('No token in interval')
            logOut()
          }
        }
      }, 1000)

      onMounted(() => {
        console.log('mounted!')
        EventBus.on("logout", () => {
          this.logOut();
        });
      })

      onUnmounted(() => {
        console.log('unmounted!')
        EventBus.remove("logout");

        clearInterval(checkJwtInterval)
      })
      
      return { logOut, tokenTtl }
    }
  }

</script>

<!-- <script>
import EventBus from "./common/EventBus";
import Base from "@/views/Base.vue";
import Login from "@/views/Login.vue";
export default {
  components: {
    Base,
    Login,
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
  created() {
    setInterval(() => {
      let token = TokenService.getLocalAccessToken();
      if (token) {
        const jwtPayload = utils.parseJwt(token);
        if (jwtPayload.exp < Date.now() / 1000) {
          store.dispatch("auth/logout");
        } else {
        }
      }
    });
  },
  mounted() {
    EventBus.on("logout", () => {
      this.logOut();
    });
  },
  beforeUnmount() {
    EventBus.remove("logout");
  },
};
</script> -->
<style lang="scss">
/* Optional: Makes the sample page fill the window. */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  height: 100%;
}

#tokenTtl {
  font-size: 8px;
  position: absolute;
  bottom: 5px;
  right: 10px;
}

</style>
