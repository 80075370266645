<template>
  <div id="cc-app-base" v-if="$store.state.auth.status.loggedIn">
    <Navbar
      :opened="opened"
      :hidenavbaritems="hideNavbarItems"
      @toggleNavOpen="onToggleNavOpen"
    ></Navbar>
    <Sidebar :opened="opened" @toggleNavOpen="onToggleNavOpen"></Sidebar>
    <div id="cc-app-content" :class="{ mainopen: opened }">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
  <div v-else>
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useScreen } from 'vue-screen'
import { useRoute } from 'vue-router'
// import ApplicationService from "../services/data.service";
// import EventBus from "../common/EventBus";

import Navbar from "@/components/layout/Navbar.vue";
import Sidebar from "@/components/layout/Sidebar.vue";
import { useStore } from 'vuex'

export default {
  components: {
    Navbar,
    Sidebar,
  },
  setup() {

    const screen = useScreen();
    const route = useRoute();
    const authenticated = ref(true);
    var opened = ref(false);
    var sidebarStayOpen = ref(false);
    const hideNavbarItems = ref(false);
    

    // Init the with options
    console.log("Window with: " + window.screen.width);
    const setSideBarState = function() {
      if (screen.width < 900) {
        console.log('Close sidebar')
        sidebarStayOpen.value = false;
        opened.value = false;
      } else {
        console.log('Open sidebar')
        sidebarStayOpen.value = true;
        opened.value = true;
      }
    }
    setSideBarState();

    watch(() => screen.width, () => {
      setSideBarState();
    })

    
    watch(() => route.params, () => {
        console.log("Router change", route);
    })
    
    // onBeforeRouteLeave((to, from) => {
    //   console.log('Route change')
    //   if (to.path != from.path) {
    //     console.log("Router change", to, from);
    //     console.log("this.sidebarStayOpen", this.sidebarStayOpen);
    //     if (!this.sidebarStayOpen && this.opened) this.opened = false;
    //   }
    // })


    return { authenticated, opened, sidebarStayOpen, hideNavbarItems };
  },
  methods: {
    onToggleNavOpen: function (value) {
      console.log(value);
      this.opened = value;
    },
  },
  // watch: {

  //   $route(to, from) {
  //     if (to.path != from.path) {
  //       console.log("Router change", to, from);
  //       console.log("this.sidebarStayOpen", this.sidebarStayOpen);
  //       if (!this.sidebarStayOpen && this.opened) this.opened = false;
  //     }
  //   },
  // },
  activated() {
    const store = useStore()
    if (!store.state.auth.status.loggedIn) {
      console.log('User is not logged in')
      //store.dispatch("auth/logout");
      console.log(this)
      if (this.$router)
        this.$router.push({ path: '/login' })
    }

  },
  mounted() {
  
  },
};
</script>

<style lang="scss">
body {
  // font-family: "Arial", Serif;
  background-color: #f5f5f5;
  overflow-x: hidden;
}

#cc-app-base {
  height: 100%;
  position: relative;
}

#cc-app-content {
  transition: margin-left 1s;
  // padding: 20px;
  // overflow: hidden;
  // width: 100%;
  position: absolute;
  bottom: 0px;
  top: 63px;
  right: 0px;
  left: 0px;
}

.mainopen {
  transition: margin-left 1s;
  // padding: 20px;
  overflow: hidden;
  // width: 100%;
  margin-left: 250px;
}
</style>