import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { FontAwesomeIcon } from './plugins/font-awesome'

import "./styles"


// Toastification
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


import setupInterceptors from './services/setupInterceptors';
setupInterceptors(store);


const toastificationOptions = {
  // You can set your default options here
};


createApp(App)
  .use(store)
  .use(router)
  .use(Toast, toastificationOptions)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app')
