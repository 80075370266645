import api from "./api";
import TokenService from "./token.service";

class AuthService {
  login({ email, password }) {
    return api
      .post("/accounts/authenticate", {
        email,
        password
      })
      .then((response) => {
        //console.log(response)
        if (response.data.accessToken) {
          TokenService.setUser(response.data);
        }

        return response.data;
      });
  }

  logout() {
    TokenService.removeUser();
  }

  register({ username, fullname, email, password, confirmPassword, acceptTerms}) {
    return api.post("/accounts/register", {
      username,
      fullname,
      email,
      password,
      confirmPassword,
      acceptTerms
    });
  }
}

export default new AuthService();
