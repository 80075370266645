import { createStore } from 'vuex'
import { auth } from "./modules/auth.module";
import { data } from "./modules/data.module";

const initialState = {
  activeRoute: 'home'
}

export default createStore({
    state: initialState,
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        data,
    }
})
