import DataService from '../../services/data.service';

//const user = JSON.parse(localStorage.getItem('user'));
// const initialState = user
//   ? { status: { loggedIn: true }, user }
//   : { status: { loggedIn: false }, user: null };
const initialState = {
    items: [],
    carrierContactInfo: {},
    collectionPoints: [],
    assignedCollectionPoints: []
}

export const data = {
    namespaced: true,
    state: initialState,
    actions: {
        getCarrierContactInfo({ commit }) {
            return DataService.dataQuery('GetCarrierContactInfo').then(
                carrierContactInfo => {
                    commit('setCarrierContactInfo', carrierContactInfo);
                    return Promise.resolve(carrierContactInfo);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        },
        getCollectionPoints({ commit }) {
            return DataService.dataQuery('GetCollectionPoints').then(
                collectionPoints => {
                    commit('addCollectionPoints', collectionPoints);
                    return Promise.resolve(collectionPoints);
                },
                error => {
                    commit('addCollectionPointsFailure');
                    return Promise.reject(error);
                }
            );
        },
        getAssignedCollectionPoints({ commit }) {
            let params = []
            params.push({
                name: 'type',
                value: 'assigned'
            })
            return DataService.dataQuery('GetCollectionPoints', params).then(
                collectionPoints => {
                    commit('addCarrierCollectionPoints', collectionPoints);
                    return Promise.resolve(collectionPoints);
                },
                error => {
                    commit('addCollectionPointsFailure');
                    return Promise.reject(error);
                }
            );
        },
        bookCollectionPoint({ commit }, orderId) {
            let params = []
            params.push({
                name: 'orderId',
                value: orderId
            })
            return DataService.dataQuery('AssignCollectionPointToCarrier', params).then(
                collectionPoints => {
                    console.log('hej')
                    commit('addCollectionPoints', collectionPoints);
                    return Promise.resolve(collectionPoints);
                },
                error => {
                    console.log('error')
                    //commit('addItemsFailure');
                    return Promise.reject(error);
                }
            );
        },
        setCollectionPointStatus({commit}, { orderId, status }) {
            let params = []
            params.push({
                name: 'orderId',
                value: orderId
            })
            params.push({
                name: 'status',
                value: status
            })
            return DataService.dataQuery('SetCollectionPointStatus', params).then(
                data => {
                    console.log(commit);
                    console.log(data);
                    //commit('setCollectionPointStatus', collectionPoint);
                    return Promise.resolve();
                },
                error => {
                    //commit('addItemsFailure');
                    return Promise.reject(error);
                }
            );
        },
    },
    mutations: {
        setCarrierContactInfo(state, carrierContactInfo) {
            if (carrierContactInfo.response) {
                state.carrierContactInfo = JSON.parse(carrierContactInfo.response)[0];
            } else {
                state.carrierContactInfo = {}
            }
        },
        addCollectionPoints(state, collectionPoints) {
            console.log('Collectionpoint added', collectionPoints);
            if (collectionPoints.response) {
                state.collectionPoints = JSON.parse(collectionPoints.response);
            } else 
            {
                state.collectionPoints = []
            }
        },
        addCarrierCollectionPoints(state, collectionPoints) {
            console.log('My collectionpoint added', collectionPoints);
            if (collectionPoints.response) {
                state.assignedCollectionPoints = JSON.parse(collectionPoints.response);
            } else {
                state.assignedCollectionPoints = []
            }
        },
        setCollectionPointStatus(state, collectionPoint) {
            console.log('Collectionpoint changed', collectionPoint);
            if (collectionPoint.response) {
                let updatedObject = JSON.parse(collectionPoint.response)[0];

                state.assignedCollectionPoints = [...state.assignedCollectionPoints.map(item => item.orderId !== updatedObject.orderId ? item : {...item, ...updatedObject})]
            } else {
                state.assignedCollectionPoints = []
            }
        },
        addItems(state, items) {
            console.log('Items added', items);
            state.items = items;
            // state.user = user;
        },
    }
};
