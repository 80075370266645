<template>

    <div id="side-menu" class="side-nav" :class="{sidenavopen: opened}">
        <img class="logo" src="../../assets/Byggprofiler_logo_webb_liten.png" />

        <router-link class="ripple" to="/">Start</router-link>
        <router-link class="ripple" to="/mapview">Kartvy</router-link>
        <router-link class="ripple" to="/pickups">Upphämtningar</router-link>
        <!-- <router-link class="ripple" to="/about">About</router-link> -->
    </div>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      required: false
    }
  },
  setup() {

  }
}
</script>

<style scoped>


.logo {
  position: absolute;
  top: 0px;
  height: 63px;
  margin: 2px 0px 0px 10px;
}
.side-nav{
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0;
  background: var(--color-sidebar-background-color); /*#111;*/
  opacity: 0.9;
  overflow-x: hidden;
  padding-top: 63px;
  transition: 1s;
}

.sidenavopen{
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0;
  background: var(--color-sidebar-background-color); /*#111;*/
  opacity: 0.9;
  overflow-x: hidden;
  padding-top: 63px;
  transition: 1s;

  box-shadow: rgb(0 0 0 / 12%) 0 1px 3px;
}

.side-nav a{
  padding: 10px 10px 10px 30px;
  text-decoration: none;
  font-size: 0px;
  color: var(--color-header-color);
  display: block;
  transition: 1s;
}

.side-nav.sidenavopen a {
  font-size: 20px
}

.side-nav a:hover{
  color: #000000;
}

.side-nav a:hover,
 .side-nav a.router-link-active,
 .side-nav a.router-link-exact-active {
    background-color: rgb(228, 228, 228);
    color: #000000;
    cursor: pointer;
 }
</style>