<template>
  <nav id="navigator" class="navbar navbar-expand-sm justify-content-end" :class="{navbaropen: opened}">
    <span class="open-slide">
      <a href="#" @click="$emit('toggleNavOpen', !opened)">
        <div>
          <div class="bar" :class="{topopen: opened}"></div>
          <div class="bar" :class="{midopen: opened}"></div>
          <div class="bar" :class="{botopen: opened}"></div>
        </div>
      </a>
    </span>
    <!-- <div class="navbar-right"> -->
      <ul class="navbar-nav ms-auto" v-if="!hidenavbaritems">
        <!-- <li class="nav-item"><router-link to="/">Home</router-link></li> -->
        <!-- <li class="nav-item"><router-link to="/about">About</router-link></li> -->
        <li class="nav-item"><a class="nav-link" v-on:click="logout"><font-awesome-icon :icon="['fas', 'door-open']" /> Logga ut</a></li>
        <li class="nav-item"><router-link to="/account"><font-awesome-icon :icon="['fas', 'user']" /> {{user.fullName || user.userName}}</router-link></li>
      </ul>
    <!-- </div> -->
  </nav>
</template>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      required: false
    },
    hidenavbaritems: {
      type: Boolean,
      required: false
    }
  },
  setup() {
    
    
    return {  }
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    logout: function(event) {
      console.log('Logging out user', event);
      this.$store.dispatch("auth/logout").then(
        () => {
          console.log('Logout successful');
          this.$router.push("/login");
        },
        (error) => {
          console.log('Logout error', error)
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    }
  }
}
</script>

<style scoped>
a {
    cursor: pointer;
}

.navbar-nav {
  flex-direction: row;
}
.navbar{
  flex-wrap: nowrap;
  background-color: var(--color-header-background-color); /* #3b5998; */
  overflow: hidden;
  height: 63px;

  /* box-shadow: rgb(0 0 0 / 12%) 0 1px 4px; */
  -webkit-box-shadow: 0 4px 12px 0 rgb(0 0 0 / 17%), 0 2px 6px rgb(0 0 0 / 8%);
  box-shadow: 0 4px 12px 0 rgb(0 0 0 / 17%), 0 2px 6px rgb(0 0 0 / 8%);

  z-index: 1;
}

.navbaropen{
  /* background-color: #3b5998; */
  overflow: hidden;
  height: 63px;
  margin-left: 250px;
}

.navbar a{
  float: left;
  display: block;
  color: var(--color-header-color);
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.navbar ul{
  margin: 8px 0 0 0;
  list-style: none;
}

.navbar a:hover{
  background-color: var(--color-header-background-color);
  color: var(--color-header-color);
}

nav{
  transition: margin-left 1s;
}

.bar{
  display: block;
  height: 5px;
  width: 35px;
  background: var(--color-header-color);
  margin: 5px auto;
}

.midopen{
  width: 0;
}
.bar{
  transition: all 1s ease;
}
.topopen{
  transform: translateY(10px) rotateZ(45deg);
}
.botopen{
  transform: translateY(-10px) rotateZ(-45deg);
}


</style>